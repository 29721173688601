import React from 'react';
import { BsLinkedin } from 'react-icons/bs';

const SocialMedia = () => {
    return (
        <div className="app__social">
            <a
                style={{ display: 'table-cell' }}
                href="https://www.linkedin.com/in/kemal-acar-579b38141/"
                target="_blank"
            >
                <div>
                    <BsLinkedin />
                </div>
            </a>
        </div>
    );
};

export default SocialMedia;
