import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import api from '../assets/api.png';
import cpp from '../assets/cpp.png';
import react from '../assets/react.png';
import cSharp from '../assets/cSharp.png';
import git from '../assets/git.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import python from '../assets/python.png';
import typescript from '../assets/typescript.png';
import NET from '../assets/NET_Core_Logo.svg';
import Angular from '../assets/Angular.png';

import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';

export default {
    email,
    mobile,
    api,
    cpp,
    git,
    html,
    javascript,
    python,
    react,
    typescript,
    profile,
    circle,
    logo,
    cSharp,
    NET,
    Angular,
};
